import { notify } from 'notiwind'

export function handleError(error, display = null) {
  error = error.error || error

  const config = useRuntimeConfig()
  if (config.public.sentry.dsn) {
    const { $sentryCaptureException } = useNuxtApp()
    $sentryCaptureException(error)
  } else {
    console.error(error)
  }

  if (display) {
    notify({
      group: 'main',
      type: 'danger',
      title: 'Une erreur s’est produite',
      text: display.length ? display : 'Si le problème persiste, merci de contacter notre équipe'
    })
  }
}
